.rmdb-actor {
  height: auto;
  min-height: 30px;
  background: #1c1c1c;
  border-radius: 20px;
  padding: 25px;
  text-align: center;
}

.rmdb-actor img {
  display: block;
  width: 100%;
  height: 300px;
  object-fit: cover;
  border-radius: 10px;
  margin: 0 auto;

}

.rmdb-actor-name {
  display: block;
  font-family: 'Abel', sans-serif;
  font-size: 22px;
  color:#fff;
  box-sizing: border-box;
  margin: 20px 0 0 0;
}

.rmdb-actor-character {
  display: block;
  font-family: 'Abel', sans-serif;
  font-size: 18px;
  color:#fff;
 
 
  box-sizing: border-box;
}