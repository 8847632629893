.rmdb-header {
  width: 100%;
  height: auto;
  background: #1c1c1c;
  padding: 0 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

.rmdb-header-content {
  max-width: 1280px;
  min-height: 120px;
  height: auto;
  padding: 20px 0px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}

.rmdb-logo {
  width: 300px;
  margin-top: 20px;
  float: left;
}

.rmdb-tmdb-logo {
  width: 122px;
  margin-top: 25px;
  float: right;
}

@media screen and (max-width: 500px) {
  .rmdb-header-content {
    max-width: 1280px;
    min-height: 0px;
  }

  .rmdb-tmdb-logo {
    display: inline-block;
    width:80px;
    margin-top: 0px;
  }

  .rmdb-logo {
    width: 150px;
    margin-top: 5px;
  }
}

.rmdb-allscriptsbtn {
  background: #777;
  width: 120px;
  min-height: 30px;
  text-align: center;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s;
  border-radius: 20px;
  margin-right:40px;
}

.rmdb-allscriptsbtn p {
  font-family: 'Abel', sans-serif;
  font-size:42px;
  padding: 20px;
}

.rmdb-allscriptsbtn:hover {
  opacity: 0.8;
}

