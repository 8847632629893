.rmdb-movieinfobar {
  width: 100%;
  height: 105px;
  background: #1c1c1c;
  position: relative;
  padding: 25px 20px 0px 20px;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  font-family: 'Abel', sans-serif;
  font-size: 22px;
}

.rmdb-movieinfobar-content {
  max-width: 1280px;
  width: 100%;
  margin: 0 auto;
  color: #fff;
}

.rmdb-movieinfobar-content-col {
  float: left;
  width: 25%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 0px 0px 0 0;
}

.rmdb-movieinfobar-content-col-original {
  float: left;
  width: 33.33%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  padding: 10px 20px 0 0;
}

.imdbBtn {
  color:#E8B708;
  cursor: pointer;
  -webkit-transform: scale(1.2,1.2);
      -ms-transform: scale(1.2,1.2);
          transform: scale(1.2,1.2);
  margin-left:10px;
}

.rmdb-movieinfobar-info {
  padding: 5px 0 0 10px;
  float: left;
}

.fa-time, .fa-revenue {
  float: left;
  margin-top: -4px;

}

.fa-budget {
  float: left;
  margin-top: -3px;
}