.rmdb-movie {
  margin-bottom: 0px;
}

.rmdb-movie-grid {
  max-width: 1280px;
  margin: 0 auto;
  padding: 0 20px;
}

.watch {
  font-size:12px;
  font-family: Arial Black, Arial, Helvetica, sans-serif;
}